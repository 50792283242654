export const WORDS = [
  'halal',
  'talon',
  'suerg',
  'tract',
  'oprou',
  'blass',
  'stier',
  'abuja',
  'buerg',
  'natur',
  'krall',
  'lamen',
  'yaren',
  'risin',
  'radio',
  'akaul',
  'spelz',
  'saaft',
  'horeg',
  'chaos',
  'eraus',
  'kaffi',
  'salut',
  'trend',
  'biddi',
  'zirka',
  'video',
  'osten',
  'grell',
  'getto',
  'revue',
  'farce',
  'promm',
  'kolen',
  'eppes',
  'capot',
  'orgie',
  'anker',
  'stock',
  'dosen',
  'genre',
  'pasta',
  'riech',
  'allze',
  'wierk',
  'krupp',
  'mound',
  'dinde',
  'schmu',
  'asien',
  'zouen',
  'mount',
  'civil',
  'pirat',
  'japan',
  'glidd',
  'atout',
  'tuten',
  'eeter',
  'pizza',
  'humor',
  'planz',
  'foire',
  'stach',
  'zitat',
  'joker',
  'disko',
  'sonat',
  'speer',
  'urban',
  'kebab',
  'mokka',
  'musel',
  'aleng',
  'schei',
  'praum',
  'virus',
  'roueg',
  'raudi',
  'spend',
  'muerd',
  'opnam',
  'rouer',
  'orbit',
  'rouge',
  'beige',
  'sauer',
  'mikro',
  'gronn',
  'allez',
  'frell',
  'dokes',
  'pesto',
  'thora',
  'buggi',
  'donst',
  'trotz',
  'basel',
  'foyer',
  'quell',
  'spray',
  'glanz',
  'troll',
  'kajak',
  'macht',
  'hirni',
  'reell',
  'allem',
  'cumin',
  'brong',
  'kaper',
  'maroc',
  'regal',
  'frust',
  'duzen',
  'komet',
  'estin',
  'moler',
  'image',
  'imker',
  'zugab',
  'staut',
  'sport',
  'hoken',
  'grapp',
  'pillo',
  'exakt',
  'siwen',
  'glach',
  'erika',
  'flemm',
  'weich',
  'immun',
  'tablo',
  'knosp',
  'otitt',
  'horas',
  'uginn',
  'athen',
  'duess',
  'sorry',
  'gotik',
  'mamma',
  'bruck',
  'mierf',
  'mulch',
  'vokal',
  'debil',
  'wuert',
  'gefor',
  'dorop',
  'stral',
  'sonde',
  'polin',
  'zweig',
  'zwerg',
  'still',
  'zigar',
  'laach',
  'fauna',
  'taart',
  'tumor',
  'zaang',
  'syrer',
  'leier',
  'adler',
  'trabb',
  'opfer',
  'friem',
  'outen',
  'modus',
  'parol',
  'toile',
  'drain',
  'jabel',
  'konto',
  'dohin',
  'stoen',
  'prima',
  'kifer',
  'guide',
  'flora',
  'cgdis',
  'rouen',
  'quonk',
  'braut',
  'dhaka',
  'schif',
  'lauer',
  'fetta',
  'tanks',
  'wopen',
  'zewee',
  'effet',
  'adden',
  'hotel',
  'taass',
  'blech',
  'klass',
  'thees',
  'angab',
  'dauer',
  'eeler',
  'saldo',
  'diwwi',
  'hoden',
  'eedel',
  'namol',
  'affix',
  'pampa',
  'ritus',
  'flapp',
  'aband',
  'baart',
  'reech',
  'pacht',
  'ditti',
  'drauf',
  'wales',
  'fuuss',
  'maart',
  'ghana',
  'stopp',
  'pujel',
  'tuerm',
  'krunn',
  'ofbau',
  'reier',
  'broch',
  'conga',
  'xenon',
  'sudan',
  'faass',
  'stoft',
  'perte',
  'fasan',
  'dreck',
  'snack',
  'aller',
  'schaz',
  'sucre',
  'haart',
  'kongo',
  'prall',
  'roman',
  'krimi',
  'stuel',
  'oplag',
  'deier',
  'ellen',
  'tuppi',
  'stamm',
  'opsaz',
  'popup',
  'dacks',
  'weyer',
  'kleed',
  'wiert',
  'orall',
  'harel',
  'bidon',
  'blend',
  'hardi',
  'donut',
  'baang',
  'mooss',
  'topeg',
  'faken',
  'grave',
  'tubak',
  'speis',
  'krich',
  'harem',
  'bidet',
  'heino',
  'stuer',
  'nuque',
  'prouf',
  'zebra',
  'wuerm',
  'verso',
  'fluch',
  'plaum',
  'rubel',
  'index',
  'fochs',
  'alarm',
  'fixer',
  'trupp',
  'merci',
  'flued',
  'paias',
  'apnoe',
  'plomp',
  'lafen',
  'label',
  'lager',
  'tozen',
  'eleng',
  'flirt',
  'rullo',
  'bonne',
  'zemol',
  'deels',
  'humus',
  'speck',
  'allen',
  'motto',
  'dakar',
  'keelt',
  'china',
  'keess',
  'neveu',
  'kolli',
  'niess',
  'vreck',
  'sicav',
  'marge',
  'woner',
  'glatz',
  'fouen',
  'klett',
  'banjo',
  'wisou',
  'eedem',
  'freed',
  'aplaz',
  'recto',
  'cours',
  'appui',
  'stull',
  'uucht',
  'ecart',
  'bakes',
  'krick',
  'peage',
  'brute',
  'legay',
  'aginn',
  'firma',
  'onsem',
  'daler',
  'penis',
  'banal',
  'arsen',
  'vaduz',
  'trapp',
  'polyp',
  'legal',
  'piano',
  'graff',
  'abtei',
  'altor',
  'vodka',
  'spull',
  'palau',
  'knall',
  'tweet',
  'wisel',
  'flank',
  'fromm',
  'strof',
  'plage',
  'kusch',
  'haiti',
  'devis',
  'bauch',
  'indiz',
  'gamay',
  'ament',
  'datum',
  'wichs',
  'flaus',
  'zweet',
  'lusch',
  'kabul',
  'gigot',
  'circa',
  'zilen',
  'doran',
  'tibbi',
  'bijou',
  'cause',
  'cadet',
  'tapis',
  'opech',
  'relax',
  'papeg',
  'niger',
  'gamma',
  'polio',
  'primm',
  'brems',
  'sujet',
  'spund',
  'volet',
  'tarif',
  'koran',
  'profi',
  'dusel',
  'glace',
  'party',
  'block',
  'ether',
  'derno',
  'hirer',
  'grumm',
  'culot',
  'stong',
  'knaus',
  'stall',
  'virum',
  'kasko',
  'grott',
  'tenue',
  'dalli',
  'chips',
  'genau',
  'dovun',
  'drill',
  'mango',
  'fazit',
  'haarz',
  'zivil',
  'depot',
  'geste',
  'steeg',
  'ligen',
  'kisel',
  'fouss',
  'filet',
  'gruef',
  'elite',
  'futur',
  'flang',
  'samoa',
  'kierb',
  'ugoen',
  'masch',
  'bravo',
  'siren',
  'flack',
  'fleck',
  'gaarf',
  'loyer',
  'maach',
  'grupp',
  'lilie',
  'ganer',
  'steif',
  'alpen',
  'pince',
  'sanaa',
  'elfin',
  'agank',
  'flair',
  'schan',
  'kaart',
  'rieds',
  'jongt',
  'vugel',
  'sicht',
  'boule',
  'schaf',
  'boxen',
  'zigel',
  'glous',
  'notiz',
  'tromp',
  'dolch',
  'villa',
  'petzi',
  'rupie',
  'purge',
  'pupes',
  'faxen',
  'gilet',
  'joint',
  'kaddo',
  'urees',
  'ollem',
  'spann',
  'muten',
  'nauru',
  'outil',
  'amant',
  'zumol',
  'ewell',
  'grass',
  'quito',
  'chape',
  'bauen',
  'bruch',
  'liter',
  'astat',
  'schal',
  'drock',
  'fokus',
  'pupen',
  'remis',
  'otite',
  'moyen',
  'genom',
  'ulass',
  'iesel',
  'zalot',
  'dofir',
  'bazar',
  'magie',
  'fuite',
  'opium',
  'staat',
  'tuyau',
  'potto',
  'klapp',
  'rebbi',
  'nasal',
  'halen',
  'seech',
  'waach',
  'poker',
  'stola',
  'schub',
  'waarz',
  'quitt',
  'mella',
  'trakt',
  'lotto',
  'toast',
  'kuerf',
  'recht',
  'offer',
  'kiosk',
  'jemen',
  'tomat',
  'hindu',
  'luxus',
  'jeans',
  'kanal',
  'argon',
  'zweck',
  'molen',
  'engel',
  'munch',
  'falen',
  'dekor',
  'bilan',
  'ausso',
  'nofro',
  'selen',
  'blann',
  'prosa',
  'klatz',
  'dorun',
  'stand',
  'sirop',
  'waarm',
  'input',
  'eepos',
  'belag',
  'kenia',
  'zooss',
  'fluor',
  'gissi',
  'daach',
  'stolz',
  'daper',
  'porno',
  'cello',
  'haass',
  'sauna',
  'meter',
  'geess',
  'breet',
  'potti',
  'gellt',
  'uewen',
  'serie',
  'laser',
  'feind',
  'klank',
  'berri',
  'paken',
  'razeg',
  'klemm',
  'ploen',
  'jupon',
  'hasch',
  'muert',
  'chile',
  'floss',
  'spart',
  'atlas',
  'briet',
  'rapid',
  'atert',
  'suite',
  'gleis',
  'etage',
  'clean',
  'dosis',
  'aktie',
  'kacka',
  'alger',
  'yacht',
  'grill',
  'ieren',
  'trema',
  'trace',
  'seuch',
  'riseg',
  'tunis',
  'nepal',
  'comic',
  'nuets',
  'spuet',
  'eisem',
  'knoll',
  'klick',
  'brout',
  'kluef',
  'kluck',
  'polen',
  'weess',
  'viral',
  'kroat',
  'moies',
  'minus',
  'stage',
  'greff',
  'mythe',
  'zwier',
  'elitt',
  'pappa',
  'onrou',
  'eeneg',
  'bauer',
  'bucki',
  'psalm',
  'spike',
  'laang',
  'jelli',
  'dusch',
  'oppen',
  'statu',
  'batti',
  'wouno',
  'mixen',
  'mixer',
  'nieft',
  'vioul',
  'feels',
  'gaass',
  'drall',
  'eefeu',
  'koler',
  'vente',
  'kanon',
  'spuer',
  'komik',
  'butzi',
  'skala',
  'sofia',
  'bulli',
  'fisem',
  'kaarp',
  'ecran',
  'rayon',
  'lobby',
  'dativ',
  'alibi',
  'krabb',
  'haler',
  'chaot',
  'moral',
  'droen',
  'basis',
  'paart',
  'blond',
  'radon',
  'schlo',
  'weder',
  'hiren',
  'dagan',
  'zapen',
  'finit',
  'chrom',
  'esseg',
  'thema',
  'timen',
  'fouer',
  'tromm',
  'polar',
  'droit',
  'hertz',
  'stack',
  'fiche',
  'duell',
  'riddo',
  'offen',
  'uruff',
  'knutt',
  'debat',
  'biber',
  'rival',
  'match',
  'frack',
  'riets',
  'zaart',
  'asiat',
  'objet',
  'kazen',
  'manie',
  'tratt',
  'ideal',
  'dajee',
  'owend',
  'folie',
  'waark',
  'glott',
  'frang',
  'pafeg',
  'fiels',
  'coupe',
  'kuerz',
  'zuwee',
  'senil',
  'bongo',
  'frech',
  'stupp',
  'flopp',
  'eiser',
  'choix',
  'fritt',
  'fatal',
  'sechs',
  'pawee',
  'benin',
  'hafen',
  'lyrik',
  'grond',
  'hanoi',
  'aacht',
  'ounst',
  'total',
  'iwwel',
  'optik',
  'datei',
  'dicht',
  'enges',
  'iener',
  'saach',
  'knaen',
  'opbau',
  'eekel',
  'super',
  'grips',
  'chute',
  'eemer',
  'ocker',
  'bluff',
  'figur',
  'blees',
  'gewan',
  'eegen',
  'nomad',
  'blitz',
  'aasch',
  'metro',
  'fuerz',
  'velar',
  'clown',
  'infam',
  'stelz',
  'sauge',
  'macho',
  'stomm',
  'fleeg',
  'krass',
  'fakel',
  'baron',
  'philo',
  'komma',
  'klont',
  'kramp',
  'titel',
  'gabun',
  'gakeg',
  'brill',
  'troun',
  'weien',
  'sklav',
  'flott',
  'grant',
  'lupus',
  'litti',
  'bross',
  'karat',
  'jomer',
  'smsen',
  'kraut',
  'herno',
  'store',
  'arees',
  'chlor',
  'mouer',
  'salsa',
  'garce',
  'arena',
  'alias',
  'ebola',
  'neger',
  'inuit',
  'dronk',
  'tusch',
  'uried',
  'gewot',
  'motiv',
  'gafel',
  'bierk',
  'netto',
  'gedam',
  'salon',
  'plott',
  'epoch',
  'vireg',
  'badge',
  'villt',
  'pissi',
  'knapp',
  'mesch',
  'wuess',
  'vegan',
  'ameis',
  'papen',
  'accra',
  'gebai',
  'genie',
  'fresk',
  'engem',
  'porto',
  'kromm',
  'ficht',
  'feier',
  'prett',
  'lover',
  'refus',
  'oflaf',
  'plack',
  'draus',
  'exfra',
  'sprit',
  'magma',
  'bibel',
  'noper',
  'plomm',
  'tacho',
  'furen',
  'hexen',
  'senat',
  'parat',
  'large',
  'proff',
  'tissu',
  'tonga',
  'gerot',
  'houer',
  'domat',
  'islam',
  'biwer',
  'muuss',
  'blumm',
  'short',
  'anlag',
  'alert',
  'affer',
  'fissi',
  'knuet',
  'radar',
  'gripp',
  'tatta',
  'nisch',
  'satin',
  'juegd',
  'arkad',
  'quarz',
  'baler',
  'hierk',
  'zeien',
  'brand',
  'spott',
  'kabes',
  'baarf',
  'usoen',
  'hauli',
  'lawin',
  'album',
  'bagel',
  'oktav',
  'douce',
  'praia',
  'kazeg',
  'furri',
  'hasel',
  'ureiz',
  'luchs',
  'onser',
  'dozou',
  'topen',
  'ozean',
  'uecht',
  'batch',
  'teint',
  'spier',
  'mobil',
  'moven',
  'grenz',
  'hirem',
  'hauch',
  'tafel',
  'daten',
  'krees',
  'drama',
  'basar',
  'intim',
  'dress',
  'dachs',
  'sputt',
  'rafen',
  'tokio',
  'lemma',
  'duuss',
  'kroun',
  'zarin',
  'naass',
  'pippi',
  'pupeg',
  'onwee',
  'ahorn',
  'agoen',
  'nujee',
  'iwwer',
  'bierg',
  'trach',
  'ekipp',
  'aktiv',
  'alter',
  'kloer',
  'titan',
  'laien',
  'areal',
  'letal',
  'greef',
  'bokal',
  'malta',
  'nylon',
  'eemol',
  'frist',
  'assel',
  'leien',
  'satir',
  'genee',
  'slash',
  'maans',
  'mumps',
  'frigo',
  'trist',
  'musek',
  'skizz',
  'desto',
  'enger',
  'pater',
  'serum',
  'froen',
  'urbop',
  'saven',
  'faart',
  'ziwwi',
  'quasi',
  'ethik',
  'suivi',
  'kampf',
  'hobby',
  'enorm',
  'panno',
  'bobbo',
  'etapp',
  'blutt',
  'aleft',
  'weier',
  'blani',
  'punkt',
  'etude',
  'inder',
  'brach',
  'gewei',
  'antik',
  'duerf',
  'loyal',
  'greis',
  'weech',
  'grouf',
  'geier',
  'aroma',
  'disco',
  'kloen',
  'smash',
  'tenor',
  'avion',
  'force',
  'doter',
  'kusin',
  'kabel',
  'damen',
  'stoff',
  'extra',
  'mitra',
  'rokad',
  'alles',
  'poeet',
  'britt',
  'queer',
  'fabel',
  'auder',
  'bless',
  'bouss',
  'falls',
  'amber',
  'moule',
  'kairo',
  'spill',
  'house',
  'hiden',
  'nomen',
  'enkel',
  'futti',
  'front',
  'mamer',
  'plang',
  'radau',
  'faarf',
  'ewech',
  'perch',
  'annex',
  'labil',
  'wodka',
  'flyer',
  'baach',
  'fusem',
  'klima',
  'handy',
  'onsen',
  'liken',
  'tosch',
  'wouer',
  'doten',
  'echec',
  'gramm',
  'ungar',
  'molch',
  'bonus',
  'tiger',
  'redon',
  'ziips',
  'freak',
  'stomp',
  'krapp',
  'apero',
  'tempo',
  'eclat',
  'lokal',
  'eisen',
  'lutte',
  'schna',
  'pipeg',
  'roden',
  'krank',
  'eckeg',
  'fiffi',
  'zwang',
  'katar',
  'salto',
  'mumie',
  'extas',
  'insel',
  'anert',
  'bigel',
  'tokyo',
  'rosen',
  'delai',
  'posch',
  'awand',
  'schro',
  'rabat',
  'dadda',
  'daarm',
  'minsk',
  'stuff',
  'wrack',
  'prema',
  'press',
  'blatz',
  'knupp',
  'urbom',
  'mauer',
  'boxer',
  'doute',
  'model',
  'logik',
  'agent',
  'bretz',
  'colis',
  'nojem',
  'nobel',
  'scout',
  'uebst',
  'emoji',
  'bento',
  'gerbe',
  'steen',
  'gummi',
  'luuss',
  'husch',
  'seoul',
  'after',
  'virop',
  'krack',
  'motor',
  'voile',
  'eidel',
  'hiert',
  'kenki',
  'kleng',
  'plomb',
  'eeben',
  'eelef',
  'virun',
  'spatz',
  'pilot',
  'idiot',
  'faser',
  'meteo',
  'stuck',
  'lodge',
  'klang',
  'schof',
  'lasur',
  'organ',
  'sober',
  'orkan',
  'kranz',
  'ufank',
  'stonn',
  'mimik',
  'ueleg',
  'awuer',
  'gebot',
  'spaut',
  'klack',
  'plach',
  'iltis',
  'tierk',
  'spoun',
  'kugel',
  'email',
  'amman',
  'anung',
  'start',
  'gezei',
  'fixen',
  'regie',
  'essen',
  'moien',
  'kakao',
  'keent',
  'zouso',
  'adder',
  'driff',
  'panik',
  'noviz',
  'kafen',
  'saier',
  'coque',
  'parad',
  'sucht',
  'kopie',
  'pente',
  'osper',
  'kader',
  'allee',
  'jeton',
  'vagin',
  'gaart',
  'salef',
  'platt',
  'baken',
  'monni',
  'event',
  'forum',
  'navet',
  'aspro',
  'agang',
  'slang',
  'trick',
]
